// import Plyr from "plyr";
// import axios from "axios";
import BPlayer from "./BPlayer";
import VideoPlaylist from "./elementor/playlist";

// import
(function ($) {
  $(document).ready(function () {
    if (typeof hpublic === "undefined") {
      return false;
    }
    hpublic.hls = hpublic.dir + "public/js/hls.min.js";
    hpublic.dash = hpublic.dir + "public/js/dash.min.js";
    // load assets if not loaded
    function h5vpLoader({ id, source, type }) {
      const element = document.getElementById(id);
      if (!element) {
        if (type === "script") {
          const script = document.createElement("script");
          script.src = `${hpublic?.dir + source}`;
          script.id = id;
          document.getElementsByTagName("head")[0].prepend(script);
        }
        if (type === "css") {
          const link = document.createElement("link");
          link.href = `${hpublic?.dir + source}`;
          link.rel = "stylesheet";
          document.getElementsByTagName("head")[0].prepend(link);
        }
      }
    }
    const assets = [
      { id: "bplugins-plyrio-css", source: "public/css/h5vp.css", type: "css" },
      { id: "h5vp-player-css", source: "public/css/style.css", type: "css" },
      { id: "bplugins-plyrio-js", source: "public/js/h5vp.js", type: "script" },
    ];

    assets.map((item) => h5vpLoader(item));
    const plyrio = document.getElementById("bplugins-plyrio-js");

    const initialize = () => {
      const players = document.querySelectorAll(".h5vp_player_initializer");
      if (players) {
        new BPlayer(players);
      }

      const videoPlaylist = $(".h5vp_video_playlist_initializer");
      VideoPlaylist.playlist(videoPlaylist);

      new BPlayer();
    };

    /**
     * This is for single video
     */
    if (typeof Plyr === "undefined") {
      plyrio.addEventListener("load", () => {
        initialize();
      });
    } else {
      initialize();
    }
  });
  // this is for elementor
  $(window).on("elementor/frontend/init", function () {
    elementorFrontend.hooks.addAction("frontend/element_ready/H5VPPlayer.default", function (scope, $) {
      new BPlayer(scope);
    });
  });
})(jQuery);
